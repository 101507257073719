module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/skills/","/zopa/","/fitmencook/","/mealpreppro/","/scorelord/","/why-bitcoin/","/bitcoin-job/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Product David","short_name":"Product David","start_url":"/","lang":"en","background_color":"#551EB9","theme_color":"#1E1E1E","theme_color_in_head":false,"display":"standalone","icon":"src/assets/image/icon.svg","localize":[{"start_url":"/es/","lang":"es","name":"David de Productos","short_name":"David de Productos","description":"Obtenga más información sobre el trabajo de David West y en qué interesa por el futuro ⚡"}],"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"06ecfe1083096af90450ce3fd6741cb0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
