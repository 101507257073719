import { rgba } from "polished";
import { breakpoints } from "./breakpoints";

const defaultColors = {
  primary: "#ff9900",
  secondary: "#5454d4",
  tertiary: "#f04037",
  white: "#ffffff",
  dark: "#19191b",
  black: "#000000",
  yellow: "#fedc5a",
  ash: "#42404C",
  green: "#52BC8E",
  teal: "#00D8C4",
  darkTeal: "#119C8E",
  orange: "#FF5E21",
  info: "#0e567c",
  gray: "#dbd9e9",
  grayBG: "#f7f7fb",
  grayBGDarker: "#f2f2f3",
  grayDark: "#ada9cd",
  score: "#EBFE42",
  scoreDark: "#32ABBD",
  pink: "#FA2848",
};

const colors = {
  primary: defaultColors.primary,
  secondary: defaultColors.secondary,
  tertiary: defaultColors.tertiary,
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.75),
  dark: defaultColors.dark,
  darkShade: rgba(defaultColors.dark, 0.75),
  ash: defaultColors.ash,
  bg: defaultColors.white,
  orange: defaultColors.orange,
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: defaultColors.dark,
  text: rgba(defaultColors.dark, 0.75),
  warning: defaultColors.yellow,
  success: defaultColors.green,
  info: defaultColors.info,
  teal: defaultColors.teal,
  darkTeal: defaultColors.darkTeal,
  darkGray: rgba(defaultColors.dark, 0.4),
  gray: defaultColors.gray,
  score: defaultColors.score,
  scoreDark: defaultColors.scoreDark,
  pink: defaultColors.pink,
  grayBG: defaultColors.grayBG,
  grayBGDarker: defaultColors.grayBGDarker,

  modes: {
    dark: {
      primary: defaultColors.primary,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.75),
      dark: defaultColors.dark,
      darkShade: rgba(defaultColors.dark, 0.75),
      ash: defaultColors.ash,
      bg: defaultColors.dark,
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.75),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      info: defaultColors.info,
      teal: defaultColors.teal,
      darkTeal: defaultColors.darkTeal,
      darkGray: rgba(defaultColors.white, 0.4),
      score: defaultColors.score,
      scoreDark: defaultColors.scoreDark,
      pink: defaultColors.pink,
      grayBG: defaultColors.grayBG,
      grayBGDarker: defaultColors.grayBGDarker,
    },
  },
};

const theme = {
  initialColorModeName: "light",
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
};

export default theme;
