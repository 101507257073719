export const menuItems = [
  {
    name: "",
    label: "About David",
    isExternal: false,
  },
  {
    name: "case-studies",
    label: "Case Studies",
    items: [
      { name: "zopa", label: "Zopa Bank" },
      { name: "mealpreppro", label: "MealPrepPro" },
      { name: "fitmencook", label: "FitMenCook" },
      { name: "scorelord", label: "ScoreLord Tennis" },
    ],
  },

    {
    name: "bitcoin",
    label: "Bitcoin",
    items: [
      { name: "why-bitcoin", label: "Why Bitcoin?" },
      { name: "bitcoin-job", label: "Next Role" },
    ],
  },
];
